import { graphql, Link } from "gatsby";
import React from "react";
import { Layout, NewsTitle, PageTitle } from "../components";

const NewsTemplate = ({ data }) => {
  const news = data.microcmsNews;

  return (
    <Layout>
      <PageTitle title="BLOG" subTitle="ブログ・お知らせ" />
      <NewsTitle
        title={news.title}
        modified={news.updatedAt}
        date={news.date}
        category={news.category[0]}
      />
      <section className="max-w-5xl mx-auto px-4 mb-16 lg:mb-24">
        <div className="mb-8">
          <img
            className="object-cover w-full lg:px-16"
            src={news.featuredImage.url}
            alt={`${news.title} 画像`}
          />
        </div>
        <div
          className="tracking-wider leading-loose text-base"
          dangerouslySetInnerHTML={{
            __html: `${news.body}`,
          }}
        />
      </section>
      <Link
        to="/blog"
        className="mb-16 lg:mb-24 block w-72 lg:w-80 mx-auto font-normal text-xs text-center tracking-widest py-4 lg:py-6 border border-primary-purple hover:bg-primary-purple hover:text-white transform duration-300"
      >
        BACK TO LIST
      </Link>
    </Layout>
  );
};

export default NewsTemplate;

export const pageQuery = graphql`
  query ($newsId: String!) {
    microcmsNews(newsId: { eq: $newsId }) {
      newsId
      featuredImage {
        url
      }
      date(formatString: "Y.MM.DD")
      category
      title
      body
      updatedAt
    }
  }
`;
